import React from "react"

const about = () => {
  return (
    <>
      <section id="about" className="relative py-20 bg-black text-white">
        <div className="container mx-auto px-4">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-1/2  mx-auto px-4" data-aos="fade-left">
              <div className="text-center">
                <h2 className="text-4xl font-bold">Un peu plus sur moi...</h2>
                <p className="mt-4 text-lg leading-relaxed">
                  Je m'appelle Lila je suis esthéticienne et maquilleuse
                  diplomée. C'est depuis toute petite que cette passion pour le
                  maquillage grandit, cet univers de possibilitées infinies et
                  colorées que je revendique comme un art. Je mets mes
                  compétences à votre disposition et vous invite à regarder mes
                  prestations et réalisations. Une question ? Contactez-moi !
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default about

import React, { useState, useEffect } from "react"
import Feed from "react-instagram-authless-feed"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)

    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong.</h1>
          <p>
            N.B. VPN/NAT source ip addresses can trigger Instagram rate limits.
          </p>
        </div>
      )
    }

    return this.props.children
  }
}

const Portfolio = () => {
  return (
    <>
      <section id="galerie" className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap">
          <div className="flex mx-auto w-full mb-20">
            <h3 className="mx-auto sm:text-3xl text-2xl font-medium title-font text-gray-900  lg:mb-0 mb-4">
              Galerie
            </h3>
          </div>

          <ErrorBoundary>
            <Feed
              userName="lila_mua_"
              className="Feed"
              classNameLoading="Loading"
              limit="8"
            />
          </ErrorBoundary>
        </div>
      </section>
    </>
  )
}

export default Portfolio

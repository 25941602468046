import React from "react"
import { FaCrown } from "@react-icons/all-files/fa/FaCrown"
import { BiDrink } from "@react-icons/all-files/bi/BiDrink"
import { MdInsertInvitation } from "@react-icons/all-files/md/MdInsertInvitation"
import { MdPhotoCamera } from "@react-icons/all-files/md/MdPhotoCamera"
import { ImPencil2 } from "@react-icons/all-files/im/ImPencil2"

const services = () => {
  return (
    <>
      <section id="services" className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="text-center mb-20">
            <h2 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
              Mes services
            </h2>
            <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
              Tarifs sur devis uniquement
            </p>
            <div className="flex mt-6 justify-center">
              <div className="w-16 h-1 rounded-full bg-black inline-flex"></div>
            </div>
          </div>
          <div className="container">
            <div className="p-4 md:w-3/3 flex flex-col text-center items-center mb-4">
              <div className="icon w-20 h-20 inline-flex items-center justify-center rounded-full bg-black  mb-5 flex-shrink-0">
                {/* <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-10 h-10"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                </svg> */}
                <FaCrown />
              </div>
              <div className="flex-grow">
                <h3 className="text-gray-900 text-lg title-font font-medium mb-3">
                  Maquillage mariée
                </h3>
                <p className="leading-relaxed text-base">
                  Pour le plus beau jour de votre vie laissez-moi vous sublimer.{" "}
                  <br />
                  Avec ou sans essai/possible d'accompagner la mariée toute la
                  journée et/ou la soirée.
                </p>
              </div>
            </div>
            <div className="p-4 md:w-3/3 flex flex-col text-center items-center mb-4">
              <div className="icon w-20 h-20 inline-flex items-center justify-center rounded-full bg-black  mb-5 flex-shrink-0">
                {/* <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-10 h-10"
                  viewBox="0 0 24 24"
                >
                  <circle cx="6" cy="6" r="3"></circle>
                  <circle cx="6" cy="18" r="3"></circle>
                  <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                </svg> */}
                <BiDrink />
              </div>
              <div className="flex-grow">
                <h3 className="text-gray-900 text-lg title-font font-medium mb-3">
                  Maquillage invitée
                </h3>
                <p className="leading-relaxed text-base">
                  Anniversaires, mariages, baptêmes. <br /> Faites-moi confiance
                  pour votre mise en beauté.
                </p>
              </div>
            </div>
            <div className="p-4 md:w-3/3 flex flex-col text-center items-center mb-4">
              <div className="icon w-20 h-20 inline-flex items-center justify-center rounded-full bg-black mb-5 flex-shrink-0">
                {/* <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-10 h-10"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg> */}
                <MdInsertInvitation />
              </div>
              <div className="flex-grow">
                <h3 className="text-gray-900 text-lg title-font font-medium mb-3">
                  Mise en beauté simple
                </h3>
                <p className="leading-relaxed text-base">
                  Un entretien d'embauche? Une sortie entre amis? <br />
                  Toutes les occasions sont bonnes pour se faire belle!
                </p>
              </div>
            </div>
            <div className="p-4 md:w-3/3 flex flex-col text-center items-center mb-4">
              <div className="icon w-20 h-20 inline-flex items-center justify-center rounded-full bg-black mb-5 flex-shrink-0">
                {/* <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-10 h-10"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg> */}
                <MdPhotoCamera />
              </div>
              <div className="flex-grow">
                <h3 className="text-gray-900 text-lg title-font font-medium mb-3">
                  Maquillage photoshoot
                </h3>
                <p className="leading-relaxed text-base">
                  Vouus êtes photographe? Modèle photo? <br /> Faites appel à
                  mes services je serais ravie de collaborer avec vous.
                </p>
              </div>
            </div>
            <div className="p-4 md:w-3/3 flex flex-col text-center items-center mb-4">
              <div className="icon w-20 h-20 inline-flex items-center justify-center rounded-full bg-black mb-5 flex-shrink-0">
                {/* <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-10 h-10"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg> */}
                <ImPencil2 />
              </div>
              <div className="flex-grow">
                <h3 className="text-gray-900 text-lg title-font font-medium mb-3">
                  Cours d'auto maquillage
                </h3>
                <p className="leading-relaxed text-base">
                  Apprenez à vous maquiller tout en vous amusant. <br /> Je vous
                  propose des cours construits et agréables.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default services

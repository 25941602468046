import React from "react"

import Hero from "../components/hero"

import Layout from "../components/layout"

import SEO from "../components/seo"
import About from "../components/about"
import Servies from "../components/services"
import Portfolio from "../components/portfolio"
import Contact from "../components/contact"

const IndexPage = () => (
  <Layout>
    <SEO title="Maquilleuse à Lyon" />
    <Hero />
    <About />
    <Servies />
    <Portfolio />
    <Contact />
  </Layout>
)

export default IndexPage

import React from "react"

const contact = () => {
  return (
    <section id="contact" className="text-gray-600 body-font relative bg-black">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-12 text-white">
          <h2 className="pb-4">Contact</h2>

          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Des questions à me poser? <br /> Je vous en prie laissez-moi un
            message ainsi que vos coordonnées!
          </p>
        </div>

        <form
          className="md:w-1/2 bg-white mx-auto rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative shadow-md"
          name="contacter"
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
        >
          {/* <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
            Feedback
          </h2>
          <p className="leading-relaxed mb-5 text-gray-600">
            Post-ironic portland shabby chic echo park, banjo fashion axe
          </p> */}
          <input type="hidden" name="bot-field" />
          <div className="relative mb-4">
            <label htmlFor="name" className="leading-7 text-sm text-gray-700">
              Nom
            </label>
            <input
              type="text"
              id="name"
              name="name"
              required="required"
              className="w-full bg-white rounded border border-gray-400 focus:border-gray-600 focus:ring-2 focus:ring-gray-100 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-600">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required="required"
              className="w-full bg-white rounded border border-gray-400 focus:border-gray-600 focus:ring-2 focus:ring-gray-100 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="message"
              className="leading-7 text-sm text-gray-600"
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              required="required"
              className="w-full bg-white rounded border border-gray-400 focus:border-gray-600 focus:ring-2 focus:ring-gray-100 h-32 text-base outline-none text-gray-800 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
            ></textarea>
          </div>
          <div data-netlify-captcha></div>
          <button
            className="text-white bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-black rounded text-lg"
            type="submit"
            value="Submit"
          >
            Envoyer
          </button>
          {/* <p className="text-xs text-gray-500 mt-3">
            Chicharrones blog helvetica normcore iceland tousled brook viral
            artisan.
          </p> */}
        </form>
      </div>
    </section>
  )
}

export default contact

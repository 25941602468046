import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"

const hero = () => {
  return (
    <>
      <div
        className="relative pt-16 pb-32 flex content-center items-center justify-center"
        style={{ minHeight: "95vh" }}
      >
        <div className="absolute top-0 w-full h-full bg-top bg-cover image">
          <span
            id="blackOverlay"
            className="w-full h-full absolute opacity-80 bg-black"
          ></span>
        </div>
        <div className="container relative mx-auto" data-aos="fade-in">
          <div className="items-center flex flex-wrap">
            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div>
                <p className="text-white font-semibold text-5xl hero-title">
                  Makeup by Lila <br />
                </p>
                <h1 className="text-white text-sm">
                  Maquilleuse à Lyon et sa région
                </h1>
                <p className="mt-4 text-lg text-gray-200">
                  Le sourire est avant tout le plus beau maquillage que l'on
                  puisse porter.
                </p>
                <AnchorLink to="/#contact">
                  <button className="bg-transparent uppercase hover:bg-white text-white font-semibold hover:text-black p-4 border border-white hover:border-transparent rounded inline-block mt-5 cursor-pointer w-32">
                    Contact
                  </button>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default hero
